<template>
  <unauthorized
    :authorized="$store.getters.can('view-reports')"
    message="Unauthorized to view Reports"
  >
    <v-container>
      <base-title title="Reports" />
      <v-row>
        <v-col
          cols="12"
          md="6"
          v-for="(group, name) in getReportGroups"
          :key="name"
        >
          <v-card
            flat
            class="my-4"
          >
            <v-card-title>
              {{ name }}
            </v-card-title>
            <v-card-text>
              <report-list-item
                v-for="(report, i) in group"
                :key="i"
                :report="report"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </unauthorized>
</template>

<script>
import ReportListItem from '@/views/Reports/components/ReportListItem'
export default {
  name: 'ReportList',
  components: { ReportListItem },
  data: () => ({
    reports: [
      {
        group: 'Purchasing',
        name: 'Cat Check-In Report',
        route: 'cat-check-in-report',
        description: 'Report detailing sales based on a period/yard/buyer',
        can: 'view-cat-check-in-report'
      },
      // {
      //   group: 'Coordinating',
      //   name: 'Check-In Hedge Report',
      //   route: 'check-in-hedge-report',
      //   description: 'A report that shows all Check-in Allocations',
      //   can: 'view-check-in-hedge-report'
      // },
      {
        group: 'Coordinating',
        name: 'Final Report List',
        route: 'final-report-list',
        description: 'Final Lot Report List',
        can: 'view-partial-report'
      },
      {
        group: 'Coordinating',
        name: 'Hedge Report',
        route: 'hedge-report',
        description: 'Hedge Report',
        // change can permission
        can: 'view-partial-report'
      },
      // Believe this is deprecated as there are no reports
      // {
      //   group: 'Coordinating',
      //   name: 'Lot Provisional List',
      //   route: 'lot-provisional-list',
      //   description: 'Provisional Report for a Lot using PPM Calculator',
      //   can: 'view-lot-provisional'
      // },
      {
        group: 'Finance / Planning',
        name: 'Profit Estimation List',
        route: 'profit-estimation-list',
        description: 'Profit Estimation List',
        can: 'view-profit-estimation'
      },
      {
        addedDate: '2022/8/06',
        group: 'Inventory',
        name: 'Smelter Shipment History Reports',
        route: 'smelter-shipment-history-report',
        description: 'Get history on catalyst weight shipped to smelters',
        can: 'view-smelter-shipment-history-report'
      },
      {
        addedDate: '2021/5/26',
        group: 'Finance / Planning',
        name: 'Weekly Spending Report List',
        route: 'spending-plan-report-list',
        description: 'Weekly Report for the next weeks projected expenses.',
        can: 'view-spending-plan-reports'
      },
      {
        addedDate: '2023/6/14',
        group: 'Assays',
        name: 'Assay Comparison by Lot',
        route: 'assay-comparison-by-lot',
        description: 'Shows a list of assay results comparing the internal and external test results.',
        can: 'view-assay-result'
      }
    ]
  }),
  computed: {
    getReportGroups () {
      // first filter out un-authorized items
      let groups = this.reports.filter(r => this.$store.getters.can(r.can))
      // sort items by name
      groups = groups.sort((a, b) => {
        if (a.name > b.name) return 1
        if (b.name > a.name) return -1
        return 0
      })
      // group the items together by their group name, if the do not have a group go in general
      return groups.reduce((g, item) => {
        const groupName = item.group || 'General'
        if (!g[groupName]) g[groupName] = []
        g[groupName].push(item)
        return g
      }, {})
    }
  }
}
</script>
